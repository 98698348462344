.formContainer {
  width: 720px;
  .title {
    margin-bottom: 50px !important;
    h1 {
      padding-left: 200px !important;
      color: #fff;
      margin-bottom: 0 !important;
    }
    span {
      padding-left: 200px !important;
      color: #e8e8e8;
    }
  }
  label {
    color: #fff !important;
    font-size: 11px;
  }
}
