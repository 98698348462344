@import "../../../styles/colors.scss";

.tableContainer {
  .title {
    color: #fff;
  }
  .functions {
    margin-bottom: 20px;
    display: flex;
    // justify-content: space-evenly;
    align-items: center;
    .mainBtn {
      border-radius: 4px;
      &:hover {
        color: $color-main-pink;
        border-color: $color-main-pink;
      }
    }
  }
  .customFilters {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    .filterBox {
      display: flex;
      flex-direction: column;
      justify-content: center;
      background: #152238;
      padding: 10px;
      border-radius: 4px;
      margin-right: 30px !important;
      box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
        0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
        0 22.3px 17.9px rgba(0, 0, 0, 0.072),
        0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
      .filterLabel {
        color: #fff;
        margin-bottom: 5px;
      }
    }
  }
  :global(.ant-table) {
    border-radius: 8px !important;
    :global(.ant-table-header) {
      border-radius: 8px !important;
    }
  }
  .tableRow {
    &:nth-child(even) {
      background-color: rgba(221, 221, 221, 0.138);
    }
  }
  .cellContent {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    overflow: hidden;
    .cellContnetTitle {
      font-weight: 500;
      color: #203354;
    }
    .cellContentSubtitle {
      color: #89898a;
      font-size: 12px;
    }
    .singleTitle {
      color: #152238;
      font-weight: 500;
    }
  }
}
